import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { SwabAnalysisEntity } from "../../types/Analysis";
import React from "react";
import { addProtocol } from "../../api/protocolAPI";
import { CreateProtocol, Protocol } from "../../types/Protocol";
import RHFTextInput from "../rhf/RHFTextInput";
import RHFCheckbox from "../rhf/RHFCheckbox";
import { Button } from "@mui/material";

interface ProtocolFormProps {
  userId: string;
}
const ProtocolForm = ({ userId }: ProtocolFormProps) => {
  const { handleSubmit } = useFormContext<CreateProtocol>();
  const createProtocol = (data: CreateProtocol) => {
    return addProtocol(userId!, { ...data, pregnant: Boolean(data.pregnant) });
  };
  return (
    <form
      id="analysis-form"
      onSubmit={handleSubmit(createProtocol)}
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <RHFTextInput name="diagnosis" label="Dijagnoza" />
      <RHFCheckbox name="pregnant" label="Trudnica" />
      <Button color="success" variant="contained" type="submit">
        Kreiraj protokol
      </Button>
    </form>
  );
};

const ProtocolFormProvider = (props: ProtocolFormProps) => {
  const methods = useForm<SwabAnalysisEntity>();
  return (
    <FormProvider {...methods}>
      <ProtocolForm {...props} />
    </FormProvider>
  );
};

export default ProtocolFormProvider;
