import React from "react";
import { Box, Typography } from "@mui/material";

const AnalysisUserDataView: React.FC<{
  label: string;
  englishLabel: string;
  value?: string;
  noMargin?: boolean;
  shouldShowEnglish?: boolean;
}> = ({ label, englishLabel, value, noMargin, shouldShowEnglish }) => (
  <Box
    display="flex"
    flexDirection="row"
    alignItems="center"
    mb={noMargin ? 0 : 2}
  >
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Typography mr={2} fontWeight="bold">
        {label}:
      </Typography>

      {!shouldShowEnglish && (
        <Typography mr={2} fontWeight="bold">
          {englishLabel}:
        </Typography>
      )}
    </Box>
    {value && <Typography>{value}</Typography>}
  </Box>
);

export default AnalysisUserDataView;
