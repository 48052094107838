import {
  AnalysisCultureLabels,
  AnalysisLabels,
  AntibioticResultType,
  Result,
  SwabAnalysisEntity,
} from "../../types/Analysis";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { get } from "lodash";

interface SwabAnalysisViewProps {
  analysis: SwabAnalysisEntity;
  protocolId: string;
  userId: string;
}

const SwabAnalysisView = ({
  userId,
  protocolId,
  analysis,
}: SwabAnalysisViewProps) => {
  return (
    <Stack spacing={2} direction="column">
      <Typography
        sx={{ fontWeight: "bold" }}
        gutterBottom
        alignSelf="flex-start"
      >
        {getSwabLabel(analysis.type, analysis.subType)} -{" "}
        {getCultureLabel(analysis.culture!)}
      </Typography>
      {/*<Typography gutterBottom>*/}
      {/*  Rezultat: {getResultLabel(analysis.result)}*/}
      {/*</Typography>*/}
      <Box sx={{ p: 2, border: "1px solid #D3D3D3" }}>
        <Typography>{analysis.comment}</Typography>
      </Box>
      {/*<Typography>{analysis.bacterias}</Typography>*/}
      {analysis.antibiotics && (
        <AntibioticsListView antibiotics={analysis.antibiotics} />
      )}
    </Stack>
  );
};

const getSwabLabel = (type: string, subtype?: string) => {
  console.log(type, subtype);
  const paths = [type];
  if (subtype) {
    paths.push(subtype);
  }
  return (
    get(AnalysisLabels, paths.join(".")) ||
    `Odrabrati naziv - ${type} - ${subtype}`
  );
};

const getCultureLabel = (culture: string) => {
  return get(AnalysisCultureLabels, culture);
};

const getResultLabel = (result: Result) => {
  switch (result) {
    case Result.NEGATIVE:
      return "Negativan";
    case Result.POSITIVE:
      return "Pozitivan";
  }
};

const AntibioticsListView = ({
  antibiotics,
}: {
  antibiotics: AntibioticResultType[];
}) => {
  return (
    <Stack spacing={1} direction="column">
      {antibiotics.map((a) => (
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ borderBottom: "1px dashed #D3D3D3" }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "14px",
              "@media print": {
                fontSize: "12px",
              },
            }}
          >
            {a.name}
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "14px",
              "@media print": {
                fontSize: "12px",
              },
            }}
          >
            {a.value}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default SwabAnalysisView;
