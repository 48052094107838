import { collection, doc, setDoc } from "firebase/firestore";
import { ref, runTransaction, DatabaseReference } from "firebase/database";
import { addDocument, firestore, database } from "../firebaseConfig";
import { User } from "../types/User";
import { set } from "lodash/fp";

const userRef = collection(firestore, "users");
const userDocRef = (id: string) => doc(firestore, "users", id);
const rtUserDocRef = ref(database, `/user-ids`);

export const addUser = async (user: User) =>
  addDocument(userRef, set("userId", await createUserId())(user));
export const editUser = (userId: string, user: Partial<User>) =>
  setDoc(userDocRef(userId), user, { merge: true });
export const deleteUser = (id: string) => editUser(id, { deleted: true });


export const addUniqueNumber = async (databaseReference: DatabaseReference) => {
  let counter = 0;
  await runTransaction(databaseReference, (value) => {
    counter = value || 1;
    return +value + 1;
  });

  return counter;
};

const createUserId = async () => {
  const counter = await addUniqueNumber(rtUserDocRef);
  return `${counter}`.padStart(6, "0");
};


