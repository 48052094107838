import { flow, set, update } from "lodash/fp";
import useFirestoreDocument from "./useFirestoreDocument";
import ProtocolEntity from "../../types/Protocol";
import normalizeFirestoreDate from "../../utils/normalizeFirestoreDate";

export default function useSingleProtocol(userId: string, protocolId: string) {
  const analysisDoc = useFirestoreDocument(
    `users/${userId}/protocols/${protocolId}`,
  );
  return (
    analysisDoc &&
    (normalizeProtocol(
      set("id", analysisDoc?.id)(analysisDoc?.data() || {}),
    ) as ProtocolEntity)
  );
}

export const normalizeProtocol = (data: any) =>
  flow(update("createdAt", normalizeFirestoreDate))(data);
