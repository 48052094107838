import { Controller, useFormContext } from "react-hook-form";
import React from "react";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

const StyledTextField = styled(TextField)`
  & .MuiFormHelperText-root {
    color: #d32f2f;
  }
  margin-bottom: 20px;
`;

interface Props {
  name: string;
  label: string;
}
const RHFTextInput = ({ name, label }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      rules={{
        required: { value: true, message: "Ovo polje je obavezno." },
      }}
      name={name}
      control={control}
      render={({ field }) => (
        <StyledTextField
          {...field}
          label={label}
          error={!!errors.type}
          helperText={
            typeof errors.type?.message === "string" ? errors.type.message : ""
          }
          style={{ marginBottom: 15 }}
        />
      )}
    />
  );
};

export default RHFTextInput;
