import React, {
  createContext,
  Dispatch, PropsWithChildren,
  SetStateAction,
  useState,
} from "react";
import { User } from "../types/User";

const UserSearchContext = createContext<
  [Partial<User>, Dispatch<SetStateAction<Partial<User>>>]
>([{}, () => {}]);

const UserSearchProvider = ({ children }: PropsWithChildren) => {
  const [userSearch, setUserSearch] = useState<Partial<User>>({});

  return (
    <UserSearchContext.Provider value={[userSearch, setUserSearch]}>
      {children}
    </UserSearchContext.Provider>
  );
};

export const useUserSearchContext = () => {
  const context = React.useContext(UserSearchContext);
  if (context === undefined) {
    throw new Error(
      "useUserSearchContext must be used within a UserSearchProvider"
    );
  }
  return context;
};

export default UserSearchProvider;
