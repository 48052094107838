import React, { useState } from "react";
import { Box, Button, Drawer, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ProtocolForm from "./ProtocolForm";

interface Props {
  userId: string;
}

const AddProtocolButton = ({ userId }: Props) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <Box alignSelf="flex-end">
        <Button
          variant="contained"
          color="success"
          startIcon={<AddIcon />}
          onClick={() => {
            setOpen(true);
          }}
        >
          Kreiraj protokol
        </Button>
      </Box>
      <Drawer
        PaperProps={{
          sx: { width: { sm: "100%", md: "30%" }, p: 3 },
        }}
        open={isOpen}
        onClose={() => setOpen(false)}
        anchor="right"
      >
        <ProtocolForm userId={userId!} />
      </Drawer>
    </>
  );
};

export default AddProtocolButton;
