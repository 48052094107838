import {Gender} from "../types/User";

const genderLabel = (gender?: Gender) => {
    if (!gender) return "";
    switch (gender) {
        case Gender.FEMALE:
            return "Zenski";
        case Gender.MALE:
            return "Muski";
    }
};

 export default genderLabel;
