import { Controller, useForm, useFormContext } from "react-hook-form";
import { FormControlLabel, Switch } from "@mui/material";

interface Props {
  name: string;
  label: string;
}
const RHFSwitch = ({ name, label }: Props) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Switch
              {...field}
              checked={field.value} // Ensure the Switch reflects the form value
            />
          }
          label={label}
        />
      )}
    />
  );
};

export default RHFSwitch;
