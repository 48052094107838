import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Drawer,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import { editAnalysis as editAnalysisApi } from "../../api/analysisAPI";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import AnalysisEntity, { Analysis, Result } from "../../types/Analysis";
import { DateTimePicker } from "@mui/lab";
import { StyledTextField } from "./AddUserButton";
import useProtocols from "../../api/hooks/useProtocols";
import ProtocolEntity from "../../types/Protocol";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { format } from "date-fns";
import UserAnalysis from "./UserAnalysis";
import AddAnalysisButton from "../analysis/AddAnalysisButton";
import RHFSwitch from "../rhf/RHFSwitch";

interface Props {
  userId: string;
}

const UserProtocols = ({ userId }: Props) => {
  const protocols = useProtocols(userId);

  return (
    <Fragment>
      {protocols?.map((protocol, i) => (
        <SingleProtocolView
          protocol={protocol}
          userId={userId}
          expanded={i === 0}
        />
      ))}
    </Fragment>
  );
};

const SingleProtocolView = ({
  protocol,
  userId,
  expanded,
}: {
  protocol: ProtocolEntity;
  userId: string;
  expanded?: boolean;
}) => (
  <Accordion defaultExpanded={expanded}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1-content"
      id="panel1-header"
    >
      <Typography component="span">
        Broj protokola: {protocol.protocolId} -{" "}
        {format(protocol.createdAt, "dd.MM.yyyy HH:mm:ss")}
        {protocol.pregnant && " - TRUDNICA"}
        {protocol.diagnosis && ` - Dijagnoza: ${protocol.diagnosis}`}
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <AddAnalysisButton userId={userId!} protocolId={protocol.id} />
      <UserAnalysis userId={userId} protocolId={protocol.id} />
    </AccordionDetails>
  </Accordion>
);

export default UserProtocols;
