import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import bacteries from "../../types/Bactery";
import React, { useEffect } from "react";
import antibiotics from "../../types/Antibiotics";
import { get } from "lodash";
import { get as getFp, map } from "lodash/fp";
import { constants } from "../../types/BacteryAndAntibiotics";
import { flow } from "lodash/fp";

const AntibioticsInputRHF = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "antibiotics",
  });

  return (
    <>
      <Box>
        <A />
      </Box>
      {/*{fields.map((item, index) => (*/}
      {/*  <Box display="flex" flexDirection="row">*/}
      {/*    <FormControl fullWidth>*/}
      {/*      <InputLabel id="input-result-label">Antibiotici</InputLabel>*/}
      {/*      <Controller*/}
      {/*        name={`antibiotics.${index}.name`}*/}
      {/*        control={control}*/}
      {/*        rules={{*/}
      {/*          required: { value: true, message: "Ovo polje je obavezno." },*/}
      {/*        }}*/}
      {/*        render={({ field }) => (*/}
      {/*          <Select*/}
      {/*            {...field}*/}
      {/*            label="Tip analize"*/}
      {/*            style={{ marginBottom: 15 }}*/}
      {/*          >*/}
      {/*            {antibiotics.map((key) => (*/}
      {/*              <MenuItem value={key} key={key}>*/}
      {/*                {key}*/}
      {/*              </MenuItem>*/}
      {/*            ))}*/}
      {/*          </Select>*/}
      {/*        )}*/}
      {/*      />*/}
      {/*    </FormControl>*/}
      {/*    <SimpleToggleButtons name={`antibiotics.${index}.value`} />*/}
      {/*  </Box>*/}
      {/*))}*/}

      <Button onClick={() => append({ name: "", value: "S" })}>Dodaj</Button>
    </>
  );
};

const A = () => {
  const { control, setValue } = useFormContext();
  const val = useWatch({ name: "bacterias" });
  useEffect(() => {
    const tempArr = flow(
      getFp(val?.[0]?.name),
      map((item) => ({ name: item })),
    )(constants);
    setValue("antibiotics", tempArr);
  }, [setValue, val]);
  return (
    <Box>
      {get(constants, val?.[0]?.name, [] as string[]).map(
        (i: any, index: number) => (
          <Controller
            name={`antibiotics.${index}.value`}
            control={control}
            render={({ field }) => (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography>{i}</Typography>
                <ToggleButtonGroup
                  {...field}
                  exclusive
                  onChange={(event, value) => field.onChange(value)}
                  value={field.value}
                  aria-label="toggle options"
                >
                  <ToggleButton value="R">R</ToggleButton>
                  <ToggleButton value="S">S</ToggleButton>
                  <ToggleButton value="I">I</ToggleButton>
                </ToggleButtonGroup>
              </Box>
            )}
          />
        ),
      )}
    </Box>
  );
};

const SimpleToggleButtons = ({ name }: { name: string }) => {
  const { control, watch } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: { value: true, message: "Ovo polje je obavezno." },
      }}
      render={({ field }) => (
        <ToggleButtonGroup
          {...field}
          exclusive
          onChange={(event, value) => {
            return field.onChange(value);
          }}
          value={field.value}
          sx={{ mx: 1 }}
        >
          <ToggleButton value="R" color="error">
            R
          </ToggleButton>
          <Button value="S" color="success">
            S
          </Button>
          <Button value="I" color="info">
            I
          </Button>
        </ToggleButtonGroup>
      )}
    />
  );
};

export default AntibioticsInputRHF;
