import useFirestoreCollection from "./useFirestoreCollection";
import { useMemo } from "react";
import { orderBy } from "firebase/firestore";
import { set } from "lodash/fp";
import { normalizeAnalysis } from "../../types/Analysis";
import Protocol from "../../types/Protocol";

export default function useProtocols(userId: string) {
  const protocolsDocs = useFirestoreCollection(
    `users/${userId}/protocols`,
    useMemo(() => [orderBy("createdAt", "desc")], []),
  );
  return protocolsDocs?.docs.map(
    (u) => normalizeAnalysis(set("id", u.id)(u.data())) as Protocol,
  ) as Protocol[];
}
