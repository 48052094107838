import React, { useState } from "react";
import {
  Box,
  Button,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Controller, useForm, useWatch } from "react-hook-form";
import { AnalyseSubTypeValues, Analysis, Result } from "../../types/Analysis";
import { addAnalysis } from "../../api/analysisAPI";
import { set } from "lodash/fp";
import { get, isEmpty } from "lodash";
import bacteries from "../../types/Bactery";
import SwabForm, { defaultAnalysis } from "../forms/SwabForm";
import CovidForm from "../forms/CovidForm";
import { styled } from "@mui/material/styles";

interface Props {
  userId: string;
  protocolId: string;
}

const AddAnalysisButton = ({ userId, protocolId }: Props) => {
  const [selectedType, setSelectedType] = useState<"COVID" | "BRIS">("BRIS");
  const [isOpen, setOpen] = useState(false);
  const onAnalysisAdd = (data: Analysis) => {
    addAnalysis(userId, protocolId, data).then(console.log);
    setOpen(false);
  };
  return (
    <>
      <Box alignSelf="flex-end">
        <Button
          variant="contained"
          color="success"
          startIcon={<AddIcon />}
          onClick={() => {
            setOpen(true);
          }}
        >
          Dodaj analize
        </Button>
      </Box>
      <Drawer
        open={isOpen}
        onClose={() => setOpen(false)}
        anchor="right"
        PaperProps={{
          style: { width: "100%" },
        }}
      >
        <Select
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value as "COVID" | "BRIS")}
        >
          <MenuItem value="COVID">Covid</MenuItem>
          <MenuItem value="BRIS">Bris</MenuItem>
        </Select>
        <Box
          style={{ height: "100%" }}
          m={10}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          {selectedType === "BRIS" ? (
            <SwabForm
              data={defaultAnalysis}
              mode="create"
              onSubmit={onAnalysisAdd}
              userId={userId}
              protocolId={protocolId}
            />
          ) : (
            <CovidForm onSubmit={() => setOpen(false)} userId={userId} />
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default AddAnalysisButton;
