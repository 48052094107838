const bacteries = [
    'Escherichia coli',
    'Klebsiella pneumoniae',
    'Proteus mirabilis',
    'Proteus vulgaris',
    'Providencia stuartii',
    'Morganella morganii',
    'Salmonella spp.',
    'Shigella spp.',
    'Enterococcus faecalis',
    'Staphylococcus aureus',
    'Staphylococcus epidermidis',
    'Streptococcus pyogenes',
    'Streptococcus agalactiae',
    'Streptococcus pneumoniae',
    'Neisseria gonorrhoeae',
    'Neisseria meningitidis',
    'Haemophilus influenzae',
    'Pseudomonas aeruginosa',
    'Bacillus cereus',
    'Clostridium difficile',
    'Corynebacterium diphtheriae',
    'Mycobacterium tuberculosis',
    'Brucella spp.',
    'Yersinia enterocolitica',
    'Vibrio cholerae',
    'Campylobacter jejuni',
    'Listeria monocytogenes',
    'Bordetella pertussis',
    'Legionella pneumophila',
    'Chlamydia trachomatis',
    'Mycoplasma pneumoniae',
    'Treponema pallidum',
    'Borellia burgdorferi',
    'Leptospira interrogans',
    'Moraxella catarrhalis',
    'Citrobacter freundii',
    'Citrobacter spp.',
    'Enterobacter aerogenes',
];

export default bacteries.sort();
