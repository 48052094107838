import {
  collection,
  doc,
  writeBatch,
  getDocs,
  collectionGroup,
} from "firebase/firestore";
import { isNil, omitBy } from "lodash/fp";
import { addDocument, firestore, setDocument } from "../firebaseConfig";
import { Analysis } from "../types/Analysis";
import { editUser } from "./usersAPI";
import { chunk } from "lodash/fp";

const analysisRef = (userId: string, protocolId: string) =>
  collection(firestore, `users/${userId}/protocols/${protocolId}/analysis`);
const analysisDocRef = (userId: string, protocolId: string, id: string) =>
  doc(firestore, `users/${userId}/protocols/${protocolId}/analysis`, id);

export const addAnalysis = (
  userId: string,
  protocolId: string,
  analysis: Analysis,
) => addDocument(analysisRef(userId, protocolId), omitBy(isNil)(analysis));
export const editAnalysis = (
  userId: string,
  protocolId: string,
  analysisId: string,
  analysis: Partial<Analysis>,
) =>
  setDocument(analysisDocRef(userId, protocolId, analysisId), analysis, {
    merge: true,
  });
export const deleteAnalysis = (
  userId: string,
  protocolId: string,
  id: string,
) => editAnalysis(userId, protocolId, id, { deleted: true });
//
// export const changeAllAnalysis = async () => {
//   const analisys = await getDocs(collectionGroup(firestore, `analysis`));
//   const chunks = chunk(499)(analisys.docs);
//
//   const b = await Promise.all(
//     chunks.map(async (a) => {
//       const batch = writeBatch(firestore);
//       a.map((value) =>
//         batch.update(value.ref, "deleted", value.data().deleted || false),
//       );
//       await batch.commit();
//     }),
//   );
//   console.log(b);
// };
