import {
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead as MuiTableHead,
  TableRow,
  Typography,
} from "@mui/material";
import formatDateLong from "../../utils/formatDateLong";
import QRCode from "react-qr-code";
import React from "react";
import CovidAnalysisEntity from "../../types/Analysis";
import AnalysisUserDataView from "./AnalysisUserDataView";
import { styled } from "@mui/material/styles";

interface CovidAnalysisProps {
  analysis: CovidAnalysisEntity;
  userId: string;
}

const StyledTableHead = styled(MuiTableHead)`
  background-color: #ccc;
`;
const CovidAnalysisView = ({ analysis, userId }: CovidAnalysisProps) => (
  <>
    <Box display="flex" flexDirection="row" justifyContent="flex-start">
      <AnalysisUserDataView
        label="Datum izdavanja"
        value={formatDateLong(analysis?.dateOfIssue)}
        englishLabel="Date of issue"
        noMargin
      />
    </Box>
    <Divider sx={{ borderBottomWidth: 5, marginY: 2 }} />
    <Typography variant="h4" gutterBottom>
      Izveštaj o ispitivanju / Test report
    </Typography>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <StyledTableHead>
          <TableRow>
            <TableCell align="center">Analiza/Analysis</TableCell>
            <TableCell align="center">Rezultat/Result</TableCell>
            <TableCell align="center">Ref. Vrednosti/Ref. Value</TableCell>
            <TableCell align="center">Metoda/Method</TableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          <TableRow
            key={"row.name"}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell align="center">{analysis?.type}*</TableCell>
            <TableCell align="center">{analysis?.result}</TableCell>
            <TableCell align="center">{analysis?.referenceValues}</TableCell>
            <TableCell align="center">
              Lumiquick Diagnostics Inc.
              <br /> Usa - Immunochromatographic
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    <Typography variant="caption" gutterBottom>
      * Ukoliko je vas rezultat POZITIVAN ili NEGATIVAN, a IMATE SIMPTOME,
      molimo vas da se javite u nadleznu COVID ambulantu
    </Typography>
    <Box my={2}>
      <Typography align="left" gutterBottom variant="body2">
        Rezultat tumačiti u skladu sa kliničkom slikom pacijenta i rezultatima
        drugih dijagnostičkih procedura.
      </Typography>
      <Typography align="left" gutterBottom variant="body2">
        Dijagnosticka specifičnost testa: 99%. Dijagnostička senzitivnost testa:
        94%.
      </Typography>
      <Typography align="left" gutterBottom variant="body2">
        Naziv testa:QUICK PROFILE COVID 619 ANTIGEN TEST(LUMIQUICK
        DIAGNOSTICS,Inc,USA)
      </Typography>
      <Typography align="left" gutterBottom variant="body2">
        Broj rešenja Ministarstva zdravlja Republike Srbije 924-530-072-49/21-09
        od 27.10.2021.
      </Typography>
      <Typography align="left" gutterBottom variant="body2">
        U skladu sa Zakonom o zaštiti stanovništva od zaraznih bolesti,pozitivan
        rezultat podleže obaveznom prijavljivanju i biće dostavljen nadležnom
        Zavodu za javno zdravlje.
      </Typography>
    </Box>
    <Box alignSelf="flex-start" pt={2}>
      <QRCode
        value={`https://www.lab.biohem.rs/user/${userId!}/analysis/${analysis.id!}`}
        size={150}
      />
    </Box>
  </>
);

export default CovidAnalysisView;
