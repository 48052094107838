import React from "react";
import { AppBar, Box, Button, Toolbar } from "@mui/material";
import { signOut } from "firebase/auth";
import { auth } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();

  const logout = () => {
    signOut(auth).then(console.log);
  };
  return (
    <Box displayPrint="none" sx={{ flexGrow: 1, margin: 0 }}>
      <AppBar position="static">
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            color="inherit"
            onClick={() => {
              navigate("/");
            }}
          >
            Pacijenti
          </Button>
          <Button color="inherit" onClick={logout}>
            Log out
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
};


export default Navbar;
