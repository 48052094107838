import React, {useMemo, useState} from "react";
import {
  Box,
  Button,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import useUsers from "../../api/hooks/useUsers";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {deleteUser, editUser} from "../../api/usersAPI";
import {useNavigate} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import UserEntity, {Gender, User} from "../../types/User";
import DatePicker from "@mui/lab/DatePicker";
import formatDateLong from "../../utils/formatDateLong";
import formatDateShort from "../../utils/formatDateShort";
import {capitalize, drop, flow, take} from "lodash/fp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {StyledTextField} from "./AddUserButton";
import genderLabel from "../../utils/genderLabel";


const UsersList = () => {
  const users = useUsers();
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const [userEnt, setUser] = useState<UserEntity>();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<User>();

  const onEditUser = (data: User) => {
    editUser(userEnt?.id || "", data);
    setOpen(false);
    reset(data);
    setUser(undefined);
  };

  const paginatedUsers = useMemo(
    () => flow(drop(page * rowsPerPage), take(rowsPerPage))(users),
    [users, rowsPerPage, page]
  );
  return (
    <>
      <Paper>
        {/*<Button onClick={changeAllAnalysis}>Obrisi</Button>*/}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ backgroundColor: "grey" }}>
              <TableRow>
                <TableCell>Evidencioni broj</TableCell>
                <TableCell>Poslednja analiza</TableCell>
                <TableCell>Ime i prezime</TableCell>
                <TableCell align="right">Jmbg</TableCell>
                <TableCell align="right">Datum rodjenja</TableCell>
                <TableCell align="right">Placena poslednja analiza</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>

            <TableBody>
              {paginatedUsers?.map((user) => (
                <TableRow
                  hover
                  style={{ cursor: "pointer" }}
                  key={user.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => navigate(`/user/${user.id}`)}
                >
                  <TableCell align="right">{user.userId || "-"} </TableCell>
                  <TableCell align="right">
                    {formatDateLong(user.lastAnalysis)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {`${capitalize(user.firstname)} ${capitalize(
                      user.lastname
                    )}`}
                  </TableCell>
                  <TableCell align="right">{user.jmbg}</TableCell>
                  <TableCell align="right">
                    {formatDateShort(user.dateOfBirth)}
                  </TableCell>
                  <TableCell align="right">
                    {user.paidLastAnalysis ===
                    undefined ? null : user.paidLastAnalysis ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <CancelIcon color="error" />
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      //@ts-ignore
                      onClick={(event) => {
                        event.stopPropagation();
                        setUser(user);
                        reset(user);
                        setOpen(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      //@ts-ignore
                      onClick={(event) => {
                        event.stopPropagation();
                        deleteUser(user.id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 150]}
          component="div"
          count={users?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, page1) => setPage(page1)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(+event.target.value);
            setPage(0);
          }}
        />
      </Paper>
      <Drawer open={isOpen} onClose={() => setOpen(false)} anchor="right">
        <Box
          style={{ height: "100%" }}
          m={10}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <form
            id="user-form"
            onSubmit={handleSubmit(onEditUser)}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Controller
              rules={{
                required: { value: true, message: "Ovo polje je obavezno." },
              }}
              name="firstname"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  label="Ime"
                  error={!!errors.firstname}
                  helperText={errors.firstname?.message}
                />
              )}
            />
            <Controller
              rules={{
                required: { value: true, message: "Ovo polje je obavezno." },
              }}
              name="lastname"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  label="Prezime"
                  error={!!errors.lastname}
                  helperText={errors.lastname?.message}
                />
              )}
            />
            <Controller
              name="jmbg"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  label="JMBG"
                  error={!!errors.jmbg}
                  helperText={errors.jmbg?.message}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  label="Email"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />

            <FormControl fullWidth>
              <InputLabel id="input-result-label">Pol</InputLabel>
              <Controller
                name="gender"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Ovo polje je obavezno.",
                  },
                }}
                render={({ field }) => (
                  <Select {...field} label="Pol">
                    {Object.keys(Gender).map((res) => (
                      <MenuItem value={res} key={res}>
                        {genderLabel(res as Gender)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <Controller
              name="dateOfBirth"
              control={control}
              rules={{
                required: { value: true, message: "Ovo polje je obavezno." },
              }}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label="Datum rodjenja"
                  renderInput={(params: any) => <TextField {...params} />}
                  inputFormat="dd.MM.yyyy"
                  mask="__.__.____"
                />
              )}
            />
          </form>
          <Button
            type="submit"
            color="success"
            variant="contained"
            form="user-form"
          >
            Ažuriraj korisnika
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

export default UsersList;
