import React from "react";
import { useParams } from "react-router-dom";
import useUser from "../api/hooks/useUser";
import UserProfile from "../components/users/UserProfile";
import { Box, Button } from "@mui/material";
import UserAnalysis from "../components/users/UserAnalysis";
import AddAnalysisButton from "../components/analysis/AddAnalysisButton";
import { addProtocol } from "../api/protocolAPI";
import AddIcon from "@mui/icons-material/Add";
import UserProtocols from "../components/users/UserProtocols";
import AddProtocolButton from "../components/protocol/AddProtocolButton";

const UserPage = () => {
  const { id: userId } = useParams();
  const user = useUser(userId!);
  return (
    <Box display="flex" flexDirection="column">
      {user && <UserProfile user={user} />}
      <Box mx={10} display="flex" flexDirection="column">
        <Box
          display="flex"
          flexDirection="row"
          pb={2}
          justifyContent="flex-end"
          gap={1}
        >
          {/*<AddAnalysisButton userId={userId!} />*/}
          <AddProtocolButton userId={userId!} />
        </Box>

        <UserProtocols userId={userId!} />
      </Box>
    </Box>
  );
};

export default UserPage;
