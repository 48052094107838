import {Controller, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import bacteries from "../../types/Bactery";
import React from "react";
import {Autocomplete} from "@mui/lab";

const BacteriasInputRHF = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "bacterias",
  });

  return (
    <Box borderTop="1px solid #AAAAAA" borderBottom="1px solid #AAAAAA" p={2}>
      <Typography gutterBottom>Bakterije</Typography>
      {fields.map((item, index) => (
        <Box display="flex" flexDirection="row">
          <FormControl fullWidth>
            <Controller
              name={`bacterias.${index}.name`}
              control={control}
              rules={{
                required: { value: true, message: "Ovo polje je obavezno." },
              }}
              render={({ field }) => (
                  <Autocomplete
                      {...field}
                      options={bacteries}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              label="Tip analize"
                              style={{ marginBottom: 15 }}
                          />
                      )}
                      onChange={(_, value) => field.onChange(value)} // Updating the form value
                  />
              )}
            />
          </FormControl>
          <Controller
            rules={{
              required: { value: true, message: "Ovo polje je obavezno." },
            }}
            name={`bacterias.${index}.value`}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                // className={classes.root}
                label="Kolicina"
                // error={!!errors.type}
                // helperText={errors.type?.message}
              />
            )}
          />
        </Box>
      ))}

      <Button onClick={() => append({ name: "", value: "" })}>
        Dodaj bakteriju
      </Button>
    </Box>
  );
};


export default BacteriasInputRHF;
