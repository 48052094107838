import React from "react";
import { Box, Button, Container, Divider, Typography } from "@mui/material";
import formatDateShort from "../utils/formatDateShort";
import logo from "../assets/logo.png";
import useSingleAnalysis from "../api/hooks/useSingleAnalysis";
import useUser from "../api/hooks/useUser";
import { Gender } from "../types/User";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import capitalizeSafe from "../utils/capitalizeSafe";
import { useNavigate, useParams } from "react-router-dom";
import CovidAnalysisView from "../components/analysis/CovidAnalysisView";
import AnalysisUserDataView from "../components/analysis/AnalysisUserDataView";
import isCovidAnalysis from "../utils/isCovidAnalysis";
import isSwabAnalysis from "../utils/isSwabAnalysis";
import SwabAnalysisView from "../components/analysis/SwabAnalysisView";
import useAnalysis from "../api/hooks/useAnalysis";
import useSingleProtocol from "../api/hooks/useSingleProtocol";

export const genderLabel = (gender?: Gender) => {
  if (!gender) return "";
  switch (gender) {
    case Gender.FEMALE:
      return "Zenski";
    case Gender.MALE:
      return "Muski";
  }
};
const AnalysisPage = () => {
  const { id, analysisId, protocolId } = useParams();
  const protocol = useSingleProtocol(id!, protocolId!);
  const analysis = useAnalysis(id!, protocolId!);
  const user = useUser(id!);
  const navigate = useNavigate();
  const isSwab = isSwabAnalysis(analysis?.[0]);
  return (
    <Box display="flex" flexDirection="column" p={2}>
      <Box style={{ alignSelf: "flex-start" }} displayPrint="none">
        <Button onClick={() => navigate(-1)} startIcon={<ArrowBackIcon />}>
          {capitalizeSafe(user?.firstname)} {capitalizeSafe(user?.lastname)}
        </Button>
      </Box>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          width: "210mm",
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography>Biohem-Mikro</Typography>
            <Typography>Mikrobioloska laboratorija</Typography>
            <Typography>Kosovska 25</Typography>
            <Typography>17520, Bujanovac</Typography>
            <Typography>biohem.mikro@gmail.com</Typography>
            <Typography>PIB: 112749786</Typography>
            <Typography>+381 (0)63 7322 623</Typography>
          </Box>
          <img src={logo} width="50%" alt="logo" />
        </Box>
        <Divider sx={{ borderBottomWidth: 5, marginY: 2 }} />
        <Box
          mb={2}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box>
            <AnalysisUserDataView
              label="Ime i prezime"
              englishLabel="Name"
              value={`${capitalizeSafe(user?.firstname)} ${capitalizeSafe(user?.lastname)}`}
              shouldShowEnglish={isSwab}
            />
            <AnalysisUserDataView
              label="Datum rodjenja"
              englishLabel="Date of birth"
              value={formatDateShort(user?.dateOfBirth)}
              shouldShowEnglish={isSwab}
            />
            <AnalysisUserDataView
              label="JMBG"
              value={user?.jmbg}
              englishLabel="Personal number"
              shouldShowEnglish={isSwab}
            />
          </Box>
          <Box>
            <AnalysisUserDataView
              label="Pol"
              value={genderLabel(user?.gender)}
              englishLabel="Gender"
              shouldShowEnglish={isSwab}
            />
            <AnalysisUserDataView
              label="E-mail"
              value={user?.email}
              englishLabel="E-mail"
              shouldShowEnglish={isSwab}
            />
            <AnalysisUserDataView
              label="Broj protokola"
              value={protocol?.protocolId}
              englishLabel="Protocol No."
              shouldShowEnglish={isSwab}
            />
            <AnalysisUserDataView
              label="Datum protokola"
              value={formatDateShort(protocol?.createdAt)}
              englishLabel="Protocol Date"
              shouldShowEnglish={isSwab}
            />
          </Box>
        </Box>
        <Divider sx={{ borderBottomWidth: 5, marginY: 2 }} />
        {analysis?.map((a) => {
          return (
            <>
              {isCovidAnalysis(a) && (
                <CovidAnalysisView analysis={a} userId={id!} />
              )}
              {isSwabAnalysis(a) && (
                <SwabAnalysisView
                  analysis={a}
                  userId={id!}
                  protocolId={protocolId!}
                />
              )}
              <Divider sx={{ borderBottomWidth: 1, marginY: 2 }} />
            </>
          );
        })}
      </Container>
    </Box>
  );
};

export default AnalysisPage;
