import { Checkbox, FormControlLabel } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import React from "react";
interface RHFCheckboxProps {
  name: string;
  label: string;
}
const RHFCheckbox = ({ name, label }: RHFCheckboxProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={<Checkbox {...field} checked={!!field.value} />}
          label={label}
        />
      )}
    />
  );
};

export default RHFCheckbox;
