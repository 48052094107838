import React, { useEffect, useState } from "react";
import "./App.css";
import UsersPage from "./pages/UsersPage";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UserPage from "./pages/UserPage";
import AnalysisPage from "./pages/AnalysisPage";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import LoginPage from "./pages/LoginPage";
import { User } from "firebase/auth";
import { auth } from "./firebaseConfig";
import Navbar from "./components/Navbar";

const theme = createTheme({
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          "@media print": {
            boxShadow: "none",
          },
        },
      },
    },
  },
});

function App() {
  const [user, setUser] = useState<User>();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((fbUser) => {
      if (fbUser) {
        setUser(fbUser);
      } else {
        setUser(undefined);
      }
    });

    // Clean up the subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <div className="App">
      <Router>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {user && <Navbar />}
            <Routes>
              {!user && <Route path="*" element={<LoginPage />} />}
              {user && (
                <>
                  <Route path="/" element={<UsersPage />} />
                  <Route path="/user/:id" element={<UserPage />} />
                  <Route
                    path="/user/:id/protocols/:protocolId/analysis/:analysisId"
                    element={<AnalysisPage />}
                  />
                </>
              )}
              <Route
                path="/user/:id/analysis/:analysisId"
                element={<AnalysisPage />}
              />
            </Routes>
          </LocalizationProvider>
        </ThemeProvider>
      </Router>
    </div>
  );
}

export default App;
