import { Box, Button, capitalize, Typography } from "@mui/material";
import React from "react";
import UserEntity from "../../types/User";
import formatDateShort from "../../utils/formatDateShort";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

interface Props {
  user: UserEntity;
}

const UserProfile: React.FC<Props> = ({ user }) => {
  const navigate = useNavigate();

  return (
    <Box m={10} display="flex" flexDirection="column">
      <Button
        onClick={() => navigate(-1)}
        startIcon={<ArrowBackIcon />}
        style={{ alignSelf: "flex-start" }}
      >
        Svi Pacijenti
      </Button>

      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h2">{`${capitalize(user.firstname)} ${capitalize(
          user.lastname
        )}`}</Typography>
        <Typography variant="h5">
          {`Datum rodjenja: ${formatDateShort(user.dateOfBirth)}`}
        </Typography>
      </Box>
      <Typography variant="h5">JMBG: {user.jmbg}</Typography>
    </Box>
  );
};

export default UserProfile;
