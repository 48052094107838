import {addDocument, database, firestore} from "../firebaseConfig";
import {set} from "lodash/fp";
import {ref} from "firebase/database";
import { Protocol } from "../types/Protocol";
import {addUniqueNumber} from "./usersAPI";
import {collection} from "firebase/firestore";

const createRtProtocolRef = () => {
    const year = new Date().getFullYear();
    return ref(database, `/user-protocols/${year}`)
}
const createProtocolId = async () => {
    const counter = await addUniqueNumber(createRtProtocolRef());
    return `${counter}`.padStart(6, "0");
};
const createProtocolsRef = (userId: string) => collection(firestore, `users/${userId}/protocols`);

export const addProtocol = async (userId: string, protocol: Omit<Protocol, 'protocolId'>) =>{
    const ref = createProtocolsRef(userId);
    return addDocument(ref, set("protocolId", await createProtocolId())(protocol));
}
