import { Controller, useForm } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Analysis, Result } from "../../types/Analysis";
import { addAnalysis } from "../../api/analysisAPI";
import { set } from "lodash/fp";
import { styled } from "@mui/material/styles";

const StyledTextField = styled(TextField)`
  & .MuiFormHelperText-root {
    color: #d32f2f;
  }
  margin-bottom: 20px;
`;
type CovidFormProps = {
  onSubmit: () => void;
  userId: string;
};

const CovidForm = ({ userId, onSubmit }: CovidFormProps) => {
  const onAddAnalysis = (data: Analysis) => {
    addAnalysis(userId, "", set("dateOfIssue", new Date())(data));
    // setOpen(false);
    onSubmit();
    reset(defaultAnalysis);
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<any>();
  return (
    <form
      id="analysis-form"
      onSubmit={handleSubmit(onAddAnalysis)}
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Controller
        rules={{
          required: { value: true, message: "Ovo polje je obavezno." },
        }}
        name="type"
        control={control}
        render={({ field }) => (
          <StyledTextField
            {...field}
            label="Tip"
            error={!!errors.type}
            helperText={
              typeof errors.type?.message === "string"
                ? errors.type.message
                : ""
            }
            style={{ marginBottom: 15 }}
          />
        )}
      />
      <Controller
        rules={{
          required: { value: true, message: "Ovo polje je obavezno." },
        }}
        name="method"
        control={control}
        render={({ field }) => (
          <StyledTextField
            {...field}
            label="Metoda"
            error={!!errors.method}
            helperText={
              typeof errors.method?.message === "string"
                ? errors.method.message
                : ""
            }
            style={{ marginBottom: 15 }}
          />
        )}
      />
      <FormControl fullWidth>
        <InputLabel id="input-result-label">Rezultat</InputLabel>
        <Controller
          name="result"
          control={control}
          rules={{
            required: { value: true, message: "Ovo polje je obavezno." },
          }}
          render={({ field }) => (
            <Select {...field} label="Rezultat" style={{ marginBottom: 15 }}>
              {Object.keys(Result).map((res) => (
                <MenuItem value={res} key={res}>
                  {res}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      <Controller
        name="price"
        control={control}
        render={({ field }) => (
          <StyledTextField
            {...field}
            type="number"
            label="Cena"
            style={{ marginBottom: 15 }}
          />
        )}
      />
    </form>
  );
};

const defaultAnalysis = {
  type: "SARS CoV-2 antigen",
  generalType: "COVID",
  method: "Lumiquick Diagnostics Inc. Usa - Immunochromatographic",
  result: Result.NEGATIVE,
  dateOfIssue: new Date(),
  dateOfSampling: new Date(),
  referenceValues: "Positive/Negative",
  deleted: false,
} as Analysis;

export default CovidForm;
