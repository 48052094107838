import Entity from "./Entity";
import { flow, update } from "lodash/fp";
import normalizeFirestoreDate from "../utils/normalizeFirestoreDate";

export interface SwabAnalysisEntity extends Entity {
  dateOfIssue: Date;
  generalType: "SWAB";
  type: string;
  subType?: string;
  culture?: string;
  result: Result;
  bacterias: BacteriaType[];
  antibiotics: AntibioticResultType[];
  comment?: string;
  deleted: boolean;
  price?: number;
  doneResult?: boolean;
  directive?: string;
}

export type BacteriaType = {
  name: string;
  value: string;
};

export type AntibioticResultType = {
  name: string;
  value: "R" | "I" | "S";
};

export interface CovidAnalysisEntity extends Entity {
  generalType: "COVID";
  result: Result;
  dateOfIssue: Date;
  type: string;
  method: string;
  referenceValues: string;
  deleted: boolean;
  price?: number;
}

export type Analysis = Omit<
  CovidAnalysisEntity | SwabAnalysisEntity,
  "createdAt" | "id"
>;

export enum Result {
  NEGATIVE = "NEGATIVE",
  POSITIVE = "POSITIVE",
}

export type AnalysisType = {
  URINOKULTURA: string;
  DMP: string;
  RESPIRATORNI_BRIS: string;
  PIOKULTURA: string;
  STOLICA: string;
  GENITALNI_SEKRETI: string;
};

export const AnalysisTypeValues: AnalysisType = {
  URINOKULTURA: "Urinokultura",
  DMP: "DMP",
  RESPIRATORNI_BRIS: "Respiratorni brisevi",
  PIOKULTURA: "Piokultura",
  STOLICA: "Stolica",
  GENITALNI_SEKRETI: "Genitalni sekreti",
};

const RESPIRATORNI_BRIS = {
  GRLO: "Grlo",
  NOS: "Nos",
  JEZIK: "Jezik",
  SPUTUM: "Sputum",
};

const PIOKULTURA = {
  D_UVO: "Desno uvo",
  L_UVO: "Levo uvo",
  D_OKO: "Desno oko",
  L_OKO: "Levo oko",
  RANE: "Rane",
  KOZE: "Kože",
  PUNKTAT: "Punktat",
  ASPIRAT: "Aspirat",
};
const STOLICA = {
  KORP_KULTURA: "Koprokultura",
  STOLICA_MIK: "Stolica mikološki",
  STOLICA_PARAZIT: "Stolica na crevne parazite",
  STOLICA_PROTOZOE: "Stolica na crevne protozoe",
  PERI_ANAL: "Perianalini bris/otisak",
};
const GENITALNI_SEKRETI = {
  VAG_CERV_BRIS: "Vaginalni/Cervikalni bris",
  MIK_UREA: "Mycoplasma/Ureaplasma",
  CHLAMYDIA: "Chlamydia trachomatis",
  URET_BRIS: "Uretralni bris",
  SK: "Spermokultura",
  UB: "Uretralni bris",
  BV: "Bris vulve",
};

const kultura_BAK_MIK = {
  BAK_MIK: "Bakteriološki/Mikološki",
  BAK: "Bakteriološki",
  MIK: "Mikološki",
};

const RESPIRATORNI_BRIS_CULTURE = {
  GRLO: kultura_BAK_MIK,
  NOS: kultura_BAK_MIK,
  JEZIK: kultura_BAK_MIK,
  SPUTUM: kultura_BAK_MIK,
};

const PIOKULTURA_CULTURE = {
  D_UVO: kultura_BAK_MIK,
  L_UVO: kultura_BAK_MIK,
  D_OKO: kultura_BAK_MIK,
  L_OKO: kultura_BAK_MIK,
  RANE: kultura_BAK_MIK,
  KOZE: kultura_BAK_MIK,
  PUNKTAT: kultura_BAK_MIK,
  ASPIRAT: kultura_BAK_MIK,
};
const GENITALNI_SEKRETI_CULTURE = {
  VAG_CERV_BRIS: kultura_BAK_MIK,
  URET_BRIS: kultura_BAK_MIK,
  SK: kultura_BAK_MIK,
  UB: kultura_BAK_MIK,
  BV: kultura_BAK_MIK,
};
export const AnalyseSubTypeValues = {
  URINOKULTURA: {},
  DMP: {},
  RESPIRATORNI_BRIS: RESPIRATORNI_BRIS,
  PIOKULTURA: PIOKULTURA,
  STOLICA: STOLICA,
  GENITALNI_SEKRETI: GENITALNI_SEKRETI,
};

export const AnalysisCulture = {
  RESPIRATORNI_BRIS: RESPIRATORNI_BRIS_CULTURE,
  PIOKULTURA: PIOKULTURA_CULTURE,
  GENITALNI_SEKRETI: GENITALNI_SEKRETI_CULTURE,
};

export const AnalysisComments = {
  RESPIRATORNI_BRIS: {
    GRLO: {
      BAK_MIK: [
        "Aerobnom kultivacijom nisu izolovane patogene bakterije.",
        "Gljive roda Candida nisu izolovane.",
      ],
    },
  },
  URINOKULTURA: [
    "Sterilan.",
    "Bakterija (npr. Escherichia coli) izolovana u broju >10^5 CFU/ml.",
    "Bakterija (npr. Escherichia coli) izolovana u broju od 10^3 - 10^5 CFU/ml.",
    "Nalaz ukazuje na kontaminaciju uzorka.",
    "Pojedinačna bakterija izolovana u malom broju (npr. <10^3 CFU/ml).",
  ],
};

export const AnalysisLabels = {
  RESPIRATORNI_BRIS: {
    GRLO: "Bris grla",
    NOS: "Bris nosa",
    JEZIK: "Bris jezika",
    SPUTUM: "Sputum",
  },
  DMP: "DMP",
  URINOKULTURA: "Urinokultura",
};

export const AnalysisCultureLabels = {
  BAK_MIK: "Bakteriološka i Mikološka kultura",
  BAK: "Bakteriološka kultura",
  MIK: "Mikološka kultura",
};

export const normalizeAnalysis = (data: any) =>
  flow(
    update("createdAt", normalizeFirestoreDate),
    update("dateOfIssue", normalizeFirestoreDate),
    update("dateOfSampling", normalizeFirestoreDate),
  )(data);

export default CovidAnalysisEntity;
