const antibioticsG1 = [
  "Penicilin G",
  "Ampicilin",
  "Amoksicilin",
  "Amoksicilin sa klavulonskom kiselinom",
  "Ampicilin sulbaktam",
  "Ceftibuten",
  "Cefuroksim",
  "Ceftriakson",
  "Gentamicin",
  "Eritromicin",
  "Roksitromicin",
  "Azitromicin",
  "Klaritromicin",
  "Klindamicin",
  "Linkomicin",
  "Levofloksacin",
  "Ofloksacin",
  "Ciprofloksacin",
  "Norfloksacin",
  "Kotrimoksazol",
  "Vankomicin",
];

const antibioticsG2 = [
  "Ampicilin",
  "Amoksicilin",
  "Amoksicili sa klavulonskom kiselinom",
  "Ampicilin sulbaktam",
  "Piperacilin i tazobaktam",
  "Cefaleksin",
  "Cefahlor",
  "Cefoksitin",
  "Ceftibuten",
  "Cefuroksim",
  "Ceftriakson",
  "Cefotaksim",
  "Ceftazidim",
  "Cefiksim",
  "Cefepim",
  "Aztreonam",
  "Imipenem",
  "Meropenem",
  "Ertapenem",
  "Tetraciklin",
  "Doksiciklin",
  "Tigeciklin",
  "Gentamicin",
  "Amikacin",
  "Netilmicin",
  "Moksifloksacin",
  "Levofloksacin",
  "Ofloksacin",
  "Ciprofloksacin",
  "Norfloksacin",
  "Kotrimoksazol",
  "Nitroxolin",
  "Colistin",
  "Fosfomicin",
  "Nitrofurantoin",
];
export const constants = {
  "Haemophilus influenzae": antibioticsG1,
  "Streptococcus pyogenes": antibioticsG1,
  "Streptococcus agalactiae": antibioticsG1,
  "Streptococcus pneumoniae": antibioticsG1,
  "Neisseria gonorrhoeae": antibioticsG1,
  "Neisseria meningitidis": antibioticsG1,
  "Moraxella catarrhalis": antibioticsG1,
  "Bacillus cereus": antibioticsG1,
  "Citrobacter freundii": antibioticsG2,
  "Citrobacter spp": antibioticsG2,
  "Enterobacter aerogenes": antibioticsG2,
  "Yersinia enterocolitica": antibioticsG2,
  "Vibrio cholerae": antibioticsG2,
  "Pseudomonas aeruginosa": antibioticsG2,
  "Campylobacter jejuni": antibioticsG2,
  "Klebsiella pneumoniae": antibioticsG2,
  "Proteus mirabilis": antibioticsG2,
  "Proteus vulgaris": antibioticsG2,
  "Providencia stuartii": antibioticsG2,
  "Morganella morganii": antibioticsG2,
  "Salmonella spp.": antibioticsG2,
  "Shigella spp.": antibioticsG2,
  "Escherichia coli": antibioticsG2,
};
